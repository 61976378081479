import React from "react"
import { Link } from "gatsby"

const ThankyouSection = () => {
  return (
    <section className="contact-area pt-100">
      <div className="container">
        <div className="section-title">
          <h2>Thank You!</h2>
          <p>Our team will get in touch with you soon.</p>
          <Link to={"/"} className="default-btn">
            <i className="flaticon-left"></i>
            {"Back to Home"}
            <span></span>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default ThankyouSection
